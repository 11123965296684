import { createApp, reactive } from "vue";

import App from "./App.vue";
import router from "./router";
import store from "./store";

import CoreuiVue from "@coreui/vue";
import CIcon from "@coreui/icons-vue";
import { iconsSet as icons } from "@/assets/icons";

import "@vueform/multiselect/themes/default.css";

import http from "@/utils/http";

const app = createApp(App);
app.use(store);
app.use(router);
app.use(CoreuiVue);
app.provide("icons", icons);
app.provide("http", reactive(http));
app.component("CIcon", CIcon);

import { Chart, registerables } from "chart.js";
Chart.register(...registerables);

app.mount("#app");
