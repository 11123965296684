import { createRouter, createWebHistory } from "vue-router";
import store from "@/store";

const routes = [
  {
    path: "/",
    component: () => import("@/layouts/DefaultLayout.vue"),
    redirect: "/dashboard",
    children: [
      {
        path: "/dashboard",
        name: "Dashboard",
        component: () =>
          import(/* webpackChunkName: "dashboard" */ "@/views/Dashboard.vue"),
        meta: {
          auth: true,
        },
      },
      {
        path: "/vote/:no",
        name: "Vote",
        component: () =>
          import(/* webpackChunkName: "dashboard" */ "@/views/Vote.vue"),
        meta: {
          auth: true,
        },
      },
      {
        path: "/stats/:no",
        name: "Stats",
        component: () =>
          import(/* webpackChunkName: "dashboard" */ "@/views/Stats.vue"),
        meta: {
          auth: true,
        },
      },
    ],
  },
  {
    path: "/home",
    name: "home",
    component: () => import("../views/Home.vue"),
    meta: {
      auth: false,
    },
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/Login.vue"),
    meta: {
      auth: false,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (!store.getters.isAuthenticated && to.meta.auth) next("/login");
  else next();
});

export default router;
