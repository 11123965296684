import axios from "axios";
import store from "@/store";

const instance = axios.create({
  // baseURL: process.env.VUE_APP_API_HOST,
  // baseURL: 'http://localhost:8080'
  baseURL: 'http://test-env.eba-e45p9fem.ap-northeast-2.elasticbeanstalk.com'
});
// const instance = axios.create();

instance.defaults.withCredentials = true;
instance.defaults.headers.common["Access-Control-Allow-Origin"] = "*";

instance.interceptors.request.use((request) => {
  if (store.getters.isAuthenticated) {
    request.headers.common.Authorization = `Bearer ${store.getters.user.accessToken}`;
  }

  return request;
});

export default instance;
