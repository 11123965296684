const user = JSON.parse(localStorage.getItem("user"));

const initUser = user
  ? {
      loggedIn: true,
      ...user,
    }
  : {
      loggedIn: false,
    };

const auth = {
  state: initUser,
  mutations: {
    STORE_USER(state, payload) {
      state.loggedIn = true;
      state.accessToken = payload.accessToken;
      state.userId = payload.userId;
    },
    DELETE_USER(state) {
      state.loggedIn = false;
      state.accessToken = null;
      state.userId = null;
    },
  },
  actions: {
    saveUser: ({ commit }, user) => {
      localStorage.setItem("user", JSON.stringify(user));
      commit("STORE_USER", user);
    },
    deleteUser: ({ commit }) => {
      localStorage.removeItem("user");
      commit("DELETE_USER");
    },
  },
  getters: {
    user: (state) => state,
    isAuthenticated: (state) => state.loggedIn,
  },
};

export default auth;
